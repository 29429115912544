const stlSerializer = require('@jscad/stl-serializer')
const jscadModeling = require('@jscad/modeling')
const jscadReglRenderer = require('@jscad/regl-renderer')

$( document ).ready(function() {

    // ********************
    // Renderer configuration and initiation.
    // ********************
    const { prepareRender, drawCommands, cameras, controls, entitiesFromSolids } = jscadReglRenderer

    const perspectiveCamera = cameras.perspective
    const orbitControls = controls.orbit

    const containerElement = document.getElementById("jscad")

    const width = containerElement.clientWidth
    const height = containerElement.clientHeight

    const state = {}

    // prepare the camera
    state.camera = Object.assign({}, perspectiveCamera.defaults)
    perspectiveCamera.setProjection(state.camera, state.camera, { width, height })
    perspectiveCamera.update(state.camera, state.camera)

    // prepare the controls
    state.controls = orbitControls.defaults

    // prepare the renderer
    const setupOptions = {
        glOptions: { container: containerElement },
    }
    const renderer = prepareRender(setupOptions)

    const gridOptions = {
        visuals: {
            drawCmd: 'drawGrid',
            show: true,
        },
        size: [3000, 3000],
        ticks: [20, 5],
        color: [
            0.1,
            0.1,
            0.1,
            1
          ],
        subColor: [
            0.35,
            0.35,
            0.35,
            1
          ],
        fadeOut: true,
        transparent: false
    }

    // const gridOptions = { // command to draw the grid
    //     visuals: {
    //       drawCmd: 'drawGrid',
    //       show: true,
    //       color: [0, 0, 0, 1],
    //       subColor: [0, 0, 1, 0.5],
    //       fadeOut: false,
    //       transparent: true
    //     },
    //     size: [200, 200],
    //     ticks: [10, 1]
    //   }

    const axisOptions = {
        visuals: {
            drawCmd: 'drawAxis',
            show: false
        },
        size: 300,
        // alwaysVisible: false,
        // xColor: [0, 0, 1, 1],
        // yColor: [1, 0, 1, 1],
        // zColor: [0, 0, 0, 1]
    }

    renderOptions = {}
    var tunableParamsApp = null;
    var resetParams = true;
    var currentManifest = null;

    const setCameraPosition = (x, y, z) => {
        if (resetParams == true) state.camera.position = [x, y, z];
        // updateView = true
        // window.requestAnimationFrame(updateAndRender)
    }

    const setCameraTarget = (x, y, z) => {
        if (resetParams == true) state.camera.target = [x, y, z]
        // updateView = true
        // window.requestAnimationFrame(updateAndRender)
    }    

    const makeid = (length) =>{
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
       return result;
    }

    const defineDynamicParams = (tunableParams) => {

        if(tunableParamsApp == null) {
    
            for(k in tunableParams) {
                if (tunableParams[k].value == undefined) {
                    tunableParams[k].value = tunableParams[k].default;
                }
                eval(`${k} = ${tunableParams[k].value}`)
            }

            tunableParamsApp = new Vue({
                el: '#parameters-panel',
                data: {
                    parameters: tunableParams,
                    positions: {
                        clientX: window.innerWidth - 10,
                        clientY: undefined,
                        movementX: 0,
                        movementY: 0
                    }
                },
                methods: {
                    updateRender: function(paramKey, e) {
                        if (tunableParamsApp.parameters[paramKey].type == "number") {
                            eval(`${paramKey} = ` + parseInt(e.target.value))
                        } else if (tunableParamsApp.parameters[paramKey].type == "boolean") {
                            eval(`${paramKey} = ` + e.target.checked)
                        }
                        doRender(currentManifest)
                    },
                    dragMouseDown: function (event) {
                        event.preventDefault()
                        // get the mouse cursor position at startup:
                        this.positions.clientX = event.clientX
                        this.positions.clientY = event.clientY
                        document.onmousemove = this.elementDrag
                        document.onmouseup = this.closeDragElement
                    },
                    elementDrag: function (event) {
                        event.preventDefault()
                        this.positions.movementX = this.positions.clientX - event.clientX
                        this.positions.movementY = this.positions.clientY - event.clientY
                        this.positions.clientX = event.clientX
                        this.positions.clientY = event.clientY
                        // set the element's new position:
                        this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
                        this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
                    },
                        closeDragElement () {
                        document.onmouseup = null
                        document.onmousemove = null
                    },
                    download: function (event) {
                        exportToSTL(currentManifest);
                    },
                    downloadAsImage: function (event) {
                        doRenderAsImage(currentManifest);
                    }
                }
            })

            tunableParamsApp.$refs.draggableContainer.style.top = '110px'
            tunableParamsApp.$refs.draggableContainer.style.left = ((window.innerWidth / 2) + 295) + 'px'
        }

        if (resetParams) {
            for(k in tunableParams) {
                if (tunableParams[k].value == undefined) {
                    tunableParams[k].value = tunableParams[k].default;
                }
                eval(`${k} = ${tunableParams[k].value}`)
            }

            tunableParamsApp.parameters = tunableParams;
            resetParams = false;
        }
    }

    const exportToSTL = (scriptToRender) => {
        // TODO: do something valuable with errors
        // try {
            eval(scriptToRender);
        // } catch (e) {
        //     console.log(e)
        //     return
        // }

        entities = entitiesFromSolids({}, solids)

        // assemble the options for rendering
        renderOptions = {
            camera: state.camera,
            drawCommands: {
                drawAxis: drawCommands.drawAxis,
                drawGrid: drawCommands.drawGrid,
                drawLines: drawCommands.drawLines,
                drawMesh: drawCommands.drawMesh
            },
            // define the visual content
            entities: [
                gridOptions,
                axisOptions,
                ...entities
            ]
        }

        const stlData = stlSerializer.serialize({binary: true}, solids)
        const blob = new Blob(stlData, {type: 'application/octet-stream'})
        url = URL.createObjectURL(blob);

        var a = document.createElement("a");
        a.style = "display: none";
        document.body.appendChild(a);
        a.href = url;
        a.download = "custom_dust_part_" + makeid(5) + ".stl";
        a.click();
        window.URL.revokeObjectURL(url);
    }

    const doRenderAsImage = (scriptToRender, vueComponent) => {

        var desiredCameraPosition = [0, 0, 0]
        var desiredCameraTarget = [0, 0, 0]

        const setCameraPosition = (x, y, z) => {
            desiredCameraPosition = [x, y, z]
        }
    
        const setCameraTarget = (x, y, z) => {
            desiredCameraTarget = [x, y, z]
        } 

        const defineDynamicParams = (tunableParams) => {
            for(k in tunableParams) {
                if (tunableParams[k].value == undefined) {
                    tunableParams[k].value = tunableParams[k].default;
                }
                eval(`${k} = ${tunableParams[k].value}`)
            }
        }

        // TODO: do something valuable with errors
        // try {
            eval(scriptToRender);
        // } catch (e) {
        //     console.log(e)
        //     return
        // }

        const entities = entitiesFromSolids({}, solids)


        // prepare the camera
        const perspectiveCamera = cameras.perspective
        const camera = Object.assign({}, perspectiveCamera.defaults)

        camera.position = desiredCameraPosition
        camera.target = desiredCameraTarget
        perspectiveCamera.setProjection(camera, camera, { width, height })
        perspectiveCamera.update(camera, camera)

        var canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height
        var gl = canvas.getContext('webgl');

        const options = {
            glOptions: { gl },
            camera,
            drawCommands: {
                // draw commands bootstrap themselves the first time they are run
                drawAxis: drawCommands.drawAxis,
                drawGrid: drawCommands.drawGrid,
                drawLines: drawCommands.drawLines,
                drawMesh: drawCommands.drawMesh
            },
            rendering: {
                background: [1, 1, 1, 1],
                meshColor: [1, 0.5, 0.5, 1], // use as default face color for csgs, color for cags
                lightColor: [1, 1, 1, 1], // note: for now there is a single preset light, not an entity
                lightDirection: [0.2, 0.2, 1],
                lightPosition: [100, 200, 100],
                ambientLightAmount: 0.3,
                diffuseLightAmount: 0.89,
                specularLightAmount: 0.16,
                materialShininess: 8.0
            },
            // next few are for solids / csg / cags specifically
            overrideOriginalColors: false, // for csg/cag conversion: do not use the original (csg) color, use meshColor instead
            smoothNormals: true,

            // data
            entities: [
                { // grid data
                // the choice of what draw command to use is also data based
                visuals: {
                    drawCmd: 'drawGrid',
                    show: false
                },
                size: [1200, 1200],
                ticks: [25, 5],
                // color: [0, 0, 1, 1],
                // subColor: [0, 0, 1, 0.5]
                },
                {
                visuals: {
                    drawCmd: 'drawAxis',
                    show: false
                },
                size: 300,
                // alwaysVisible: false,
                // xColor: [0, 0, 1, 1],
                // yColor: [1, 0, 1, 1],
                // zColor: [0, 0, 0, 1]
                },
                ...entities
            ]
        }

        // prepare
        const render = prepareRender(options)

        // do the actual render
        render(options)

        canvas.toBlob(function(blob) {
            var newImg = document.createElement('img'),
                url = URL.createObjectURL(blob);
          
            newImg.onload = function() {
              // no longer need to read the blob so it's revoked
            //   URL.revokeObjectURL(url);
            };
          
            newImg.src = url;
            vueComponent.$el.appendChild(newImg);
        }, 'image/png');
    }


    const doRender = (scriptToRender) => {
        // TODO: do something valuable with errors
        // try {
            eval(scriptToRender);
        // } catch (e) {
        //     console.log(e)
        //     return
        // }

        entities = entitiesFromSolids({}, solids)

        // assemble the options for rendering
        renderOptions = {
            camera: state.camera,
            drawCommands: {
                drawAxis: drawCommands.drawAxis,
                drawGrid: drawCommands.drawGrid,
                drawLines: drawCommands.drawLines,
                drawMesh: drawCommands.drawMesh
            },
            // define the visual content
            entities: [
                gridOptions,
                axisOptions,
                ...entities
            ]
        }

        updateView = true
        window.requestAnimationFrame(updateAndRender)

    }

    // the heart of rendering, as themes, controls, etc change
    let updateView = true

    const doRotatePanZoom = () => {
        if (rotateDelta[0] || rotateDelta[1]) {
            const updated = orbitControls.rotate({ controls: state.controls, camera: state.camera, speed: rotateSpeed }, rotateDelta)
            state.controls = { ...state.controls, ...updated.controls }
            updateView = true
            rotateDelta = [0, 0]
        }

        if (panDelta[0] || panDelta[1]) {
            const updated = orbitControls.pan({ controls:state.controls, camera:state.camera, speed: panSpeed }, panDelta)
            state.controls = { ...state.controls, ...updated.controls }
            panDelta = [0, 0]
            state.camera.position = updated.camera.position
            state.camera.target = updated.camera.target
            updateView = true
        }

        if (zoomDelta) {
            const updated = orbitControls.zoom({ controls:state.controls, camera:state.camera, speed: zoomSpeed }, zoomDelta)
            state.controls = { ...state.controls, ...updated.controls }
            zoomDelta = 0
            updateView = true
        }

        $("#debug").text(`${state.camera.position[0].toFixed(2)}, ${state.camera.position[1].toFixed(2)}, ${state.camera.position[2].toFixed(2)}\n${state.camera.target[0].toFixed(2)}, ${state.camera.target[1].toFixed(2)}, ${state.camera.target[2].toFixed(2)}`)
    }

    const updateAndRender = (timestamp) => {
        doRotatePanZoom()

        if (updateView) {
            const updates = orbitControls.update({ controls: state.controls, camera: state.camera })
            state.controls = { ...state.controls, ...updates.controls }
            updateView = state.controls.changed // for elasticity in rotate / zoom

            state.camera.position = updates.camera.position
            perspectiveCamera.update(state.camera)

            renderer(renderOptions)
        }
        window.requestAnimationFrame(updateAndRender)
    }

    // window.requestAnimationFrame(updateAndRender)

    // convert HTML events (mouse movement) to viewer changes
    let lastX = 0
    let lastY = 0

    const rotateSpeed = 0.002
    const panSpeed = 1
    const zoomSpeed = 0.08
    let rotateDelta = [0, 0]
    let panDelta = [0, 0]
    let zoomDelta = 0
    let pointerDown = false

    const moveHandler = (ev) => {
        if(!pointerDown) return
        const dx = lastX - ev.pageX 
        const dy = ev.pageY - lastY 

        const shiftKey = (ev.shiftKey === true) || (ev.touches && ev.touches.length > 2)
        if (shiftKey) {
            panDelta[0] += dx
            panDelta[1] += dy
        } else {
            rotateDelta[0] -= dx
            rotateDelta[1] -= dy
        }

        lastX = ev.pageX
        lastY = ev.pageY

        ev.preventDefault()
    }

    const downHandler = (ev) => {
        pointerDown = true
        lastX = ev.pageX
        lastY = ev.pageY
        containerElement.setPointerCapture(ev.pointerId)
        ev.preventDefault()
    }

    const upHandler = (ev) => {
        pointerDown = false
        containerElement.releasePointerCapture(ev.pointerId)
        ev.preventDefault()
    }

    const wheelHandler = (ev) => {
        zoomDelta += ev.deltaY
        ev.preventDefault()
    }

    containerElement.onpointermove = moveHandler
    containerElement.onpointerdown = downHandler
    containerElement.onpointerup = upHandler
    containerElement.onwheel = wheelHandler

    Vue.component('design-preview', {
        props:['manifest'],
        template: '<div></div>',
        created: function (e) {
            manifest = atob(this._props.manifest);
            doRenderAsImage(manifest, this);
        },
        methods: {}
    });

    // load catalog
    $.getJSON( "catalog.json", function( data ) {
        
        currentManifest = firstManifest = atob(data.designs[0].manifest)
        doRender(firstManifest)

        var app4 = new Vue({
            el: '#design-menu',
            data: {
                designs: data.designs
            },
            methods: {
                open: function(design) {
                    resetParams = true;
                    manifest = atob(design.manifest);
                    currentManifest = manifest;
                    doRender(manifest);
                }
            }
        })
    });
});